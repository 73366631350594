<template>
  <div>
    <CleaningHours :user="$auth.user.sub" />
  </div>
</template>

<script>
import CleaningHours from "./CleaningHours.vue";

export default {
  name: "HomeView",
  components: { CleaningHours },
  data() {
    return {};
  },
  methods: {
  },
};
</script>