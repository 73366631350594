<template>
  <div>
    <v-row no-gutters justify="center" class="fill-height">
      <v-col align-self="center" class="fill-height">
      <v-card tile outlined height="100%">
        <v-card-title class="justify-center">
          {{ "Cleaning Hours" }}
        </v-card-title>

        <v-card-actions class="justify-center">
          <div>
            <v-date-picker
              v-model="dates"
              multiple
              no-title
              @input="updateDates"
              :picker-date.sync="monthChange"
            >
            </v-date-picker>

            <div v-for="(value, name) in days" v-bind:key="name" class="pl-4">
              <p v-if="value != 0">
                {{ name.charAt(0).toUpperCase() + name.slice(1) }}:
                {{ value }} &times; {{ rate }} = {{ value * rate }}
              </p>
            </div>

            <p class="pl-4">
              Total:
              {{
                days.sunday * rate +
                days.monday * rate +
                days.tuesday * rate +
                days.wednesday * rate +
                days.thursday * rate +
                days.friday * rate +
                days.saturday * rate
              }}
            </p>
          </div>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-col align-self="center" class="fill-height">
      <v-card tile outlined height="100%">
        <v-card-title class="justify-center">
          {{ "PT Cleaning Hours" }}
        </v-card-title>

        <v-card-actions class="justify-center">
          <div>
            <v-date-picker
              v-model="dates"
              multiple
              no-title
              @input="updateDates"
              :picker-date.sync="monthChange"
            >
            </v-date-picker>

            <div v-for="(value, name) in days" v-bind:key="name" class="pl-4">
              <p v-if="value != 0">
                {{ name.charAt(0).toUpperCase() + name.slice(1) }}:
                {{ value }} &times; {{ ptRate }} = {{ value * ptRate }}
              </p>
            </div>

            <p class="pl-4">
              Total:
              {{
                days.sunday * ptRate +
                days.monday * ptRate +
                days.tuesday * ptRate +
                days.wednesday * ptRate +
                days.thursday * ptRate +
                days.friday * ptRate +
                days.saturday * ptRate
              }}
            </p>
          </div>
        </v-card-actions>
      </v-card>
    </v-col>
    </v-row>
    
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";

export default {
  name: "CleaningHours",
  props: {
    user: { type: String, default: "reg" },
  },
  data: () => ({
    baseUrl: "https://shielded-fortress-16685.herokuapp.com/timesheet",
    //baseUrl: "http://localhost:3000/timesheet",

    monthChange: null,
    month: "",
    dates: [""],
    days: {
      sunday: 0,
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
    },
    rate: 100,
    ptRate: 50,
    monthObject: null,
  }),
  watch: {
    /**
     * Cleaning Hours month change
     */
    async monthChange(yearMonth) {
      this.month = yearMonth;
      await this.getTimesheet(this.user, yearMonth);
    },
  },
  methods: {
    async getTimesheet(user, month) {
      axios.get(`${this.baseUrl}/${user}/${month}`).then((res) => {
        // if we have a response, update
        if (res.data.length != 0) {
          this.monthObject = res.data[0];
          this.month = this.monthObject.yearMonth;
          this.dates = this.monthObject.dates;
          this.days = this.monthObject.days;
        } else {
          // if not, its a new month, reset data
          this.dates = [""];
          this.prepDays();
          this.monthObject = null;
        }
      });
    },
    async createTimesheet(user, month) {
      axios
        .post(this.baseUrl, {
          name: user,
          yearMonth: month,
          dates: this.dates,
          days: this.days,
        })
        .then((res) => {
          this.monthObject = res.data;
          this.month = this.monthObject.yearMonth;
          this.dates = this.monthObject.dates;
          this.days = this.monthObject.days;
        });
    },
    async updateTimesheet(user, month) {
      axios
        .patch(`${this.baseUrl}/${user}/${month}`, {
          name: user,
          yearMonth: month,
          dates: this.dates,
          days: this.days,
        })
        .then((res) => {
          this.monthObject = res.data;
          this.month = this.monthObject.yearMonth;
          this.dates = this.monthObject.dates;
          this.days = this.monthObject.days;
        });
    },
    /**
     * Regular days methods
     */
    async updateDates(dates) {
      this.prepDays();
      this.countDays(dates);

      // if we have data, then call patch
      if (this.monthObject) {
        await this.updateTimesheet(this.user, this.month);
      } else {
        // if not then post
        await this.createTimesheet(this.user, this.month);
      }
    },
    prepDays() {
      this.days = {
        sunday: 0,
        monday: 0,
        tuesday: 0,
        wednesday: 0,
        thursday: 0,
        friday: 0,
        saturday: 0,
      };
    },
    countDays(dates) {
      dates.forEach((date) => {
        if (date != "") {
          let day = moment(date).format("dddd");
          this.addToDays(day);
        }
      });
    },
    addToDays(day) {
      switch (day) {
        case "Sunday":
          this.days.sunday += 1;
          break;
        case "Monday":
          this.days.monday += 1;
          break;
        case "Tuesday":
          this.days.tuesday += 1;
          break;
        case "Wednesday":
          this.days.wednesday += 1;
          break;
        case "Thursday":
          this.days.thursday += 1;
          break;
        case "Friday":
          this.days.friday += 1;
          break;
        case "Saturday":
          this.days.saturday += 1;
          break;
        default:
          break;
      }
    },
  },
};
</script>